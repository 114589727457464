@import 'abstracts/imports';

// GENERAL Multiselect styles
#app {
  .mat-select-search-inner {
    border-color: map-get($ald-colors, 'gray-200');
  }
  .mat-select-search-panel .mat-option-pseudo-checkbox {
    display: none;
  }

  .mat-chip-list-wrapper {
    padding: 4px
  }

  .mat-option-text {
    @include font-size(13px);
    font-weight: 500;
    color: map-get($ald-colors, 'primary');
  }
  .mat-option.mat-option-disabled .mat-option-text{
    @include font-size(13px);
    font-weight: 500;
    color: #cecece;
  }
  .mat-select-search-inside-mat-option{
    .mat-select-search-input {
      padding: 16px 36px 16px 16px!important;
      box-sizing: border-box;
      font-size: 16px;
      height: initial;
      line-height: initial;
    }
  }
}

#app .multiselect {
  &-select {
    margin-top: 20px;
    display: block;
  }

  &-loading {
    position: relative;
    background-color: white;

    &-spinner {
      @include center(horizontal);
      top: 15px;

      &:before {
        content: none;
      }
    }
  }
  &-close {
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      margin: 10px 10px 5px 0;
  }

  &-option {
    transition: max-height .2s ease;
    max-height: 100px;
    opacity: 1;

    &.child, &.grandchild {
      &:before {
        @include icon(icon-corner-down-right);
        opacity: 0.5;
        padding-right: 5px;
        @include font-size(12px);
      }
    }

    &.child {
      padding-left: 20px;
    }

    &.grandchild {
      padding-left: 40px;
    }

    &.hidden {
      opacity: 0;
      visibility: hidden;
      max-height: 0;
      backface-visibility: hidden;
    }
  }

  &-max-items {
    float: left;
    position: relative;
    top: -20px;
    @include font-size(12px);
    color: rgba(0, 0, 0, 0.54);
  }

  &-clear-selection-old {
    color: map-get($ald-colors, 'primary');
    float: right;
    position: relative;
    top: -20px;
    cursor: pointer;
    @include font-size(12px);

    &:hover {
      color: darken(map-get($ald-colors, 'primary-light'), 10%);
      text-decoration: underline;
    }
  }

  &-chips {
    font-family: $font-family;
    width: 100%;
  }

  &-no-entries-found {
    @include font-size(13px);
    font-weight: 500;
    padding: 16px;
  }

  .mat-pseudo-checkbox {
    border-width: 1px;
    border-color: map-get($ald-colors, 'gray-300');

    &:after {
      top: 4px;
    }
  }

  .mat-option-pseudo-checkbox {
    margin-right: 15px;
  }
  
  .mat-select-search-inner {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .mat-select-search-no-entries-found {
    @include font-size(13px);
    font-weight: 500;
  }

  .mat-chip {
    border-radius: $border-radius;
    border: 1px solid darken(map-get($ald-colors, 'gray-100'), 5%);
    margin: 2px;
    padding: 9px 8px 9px 12px;
    background-color: map-get($ald-colors, 'gray-100');
    display: inline-flex;
    align-items: center;
    @include font-size(11px);
    color: map-get($ald-colors, 'primary');

    &:focus {
      outline: none;
    }

    &-remove {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      margin-left: 8px;
      color: darken(map-get($ald-colors, 'gray-600'), 10%);
      @include font-size(14px);
      font-weight: 600;
      transition: all .2s ease;
      transform: scale(0.9);
      transform-origin: 50%;
      backface-visibility: hidden;

      &:hover {
        color: darken(map-get($ald-colors, 'gray-700'), 10%);
        transform: scale(1);
      }
    }
  }
}
