@import 'abstracts/imports';

#app .mat-step {
  &-header {
    &:hover{
      background-color: transparent;
    }
  }
}

#app .mat-vertical-content-container{
  padding: 20px 0;
}
