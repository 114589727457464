@import 'abstracts/imports';

#app .mat-tab{
  &-nav-bar {
    border-color: map-get($ald-colors, 'gray-200');
    .mat-ink-bar{
      display: none;
      bottom: initial;
      top: 0;
    }
  }
  &-link{
    background-color: rgba(0,0,0,0.05);
    text-transform : uppercase;
    font-weight: 900;
    @include font-size(11px);
    letter-spacing: 0.5px;
    padding-left:30px;
    padding-right: 30px;
    border-top: 1px solid map-get($ald-colors, 'gray-200');
    border-left: 1px solid map-get($ald-colors, 'gray-200');
    &:last-child {
      border-right: 1px solid map-get($ald-colors, 'gray-200');
    }
  }
  &-label{
    &-active{
      color: map-get($ald-colors, 'primary');
      border-top: map-get($ald-colors, 'primary-light') 2px solid;
      background-color: white;
    }
  }
}


//## Generic tab styles
.tab {
  &-content{
    background-color: white;
    border-left: 1px solid map-get($ald-colors, 'gray-200');
    border-right: 1px solid map-get($ald-colors, 'gray-200');
    border-bottom: 1px solid map-get($ald-colors, 'gray-200');
    min-height: 250px;
    position: relative;
  }
  &-loading {
    text-align: center;
    display: block;
  }
  &-no-content {
    color: map-get($ald-colors, 'gray-600');
    text-transform: uppercase;
    position: relative;
    top: 7rem;
    text-align: center;
    font-size: 0.75rem;
    background-color: white;
    font-weight: 600;
    z-index: 1;
    height: 0;
    @include font-size(12px);
  }
}
