@import "abstracts/_imports";

// GENERAL Angular Expansion panel
#app .mat-expansion-panel {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  transition: all .2s ease;
  border-radius: $border-radius;
  background-color: white;

  &:not(.mat-expanded):hover {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.10);
  }
  &-header:hover{
    background-color: white;
  }

  &-header {
    height: 48px;
  }

  &-header-title {
    @include font-size(12px);
    font-weight: 600;
  }
  &-header-description {
    @include font-size(12px);
  }
}
