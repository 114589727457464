@import "abstracts/_imports";

// GENERAL Table Container & Elements styles
.mat-table {

  &-bullet {
    &:before {
      content: '';
      width: 4px;
      height: 4px;
      background-color: map-get($ald-colors, 'success');
      border-radius: 50%;
      margin-right: 5px;
    }

    &.success:before {
      background-color: map-get($ald-colors, 'success');;
    }

    &.warning:before {
      background-color: map-get($ald-colors, 'warning');;
    }

    &.danger:before {
      background-color: map-get($ald-colors, 'danger');;
    }
  }
  &-container {
    width: 100%;
    display: inline-block;

    .expanded-row {
      height: auto;
      font-weight: 500;
      background-color: transparentize(map-get($ald-colors, 'primary'), 0.98);
      &:after {
        transform: rotate(180deg);
      }
    }

    .expanded {
      background-color: transparentize(map-get($ald-colors, 'primary'), 0.98);
      padding-left: 6%;

      &-wrapper {
        display: flex;
        align-items: flex-start;
        padding: 0 12px 12px 0;
        flex-wrap: wrap;
        width: 100%;

        &-title {
          font-weight: 600;
          color: map-get($ald-colors, 'primary');
        }

        &-user {
          margin: 20px 5px 0 0;
          width: 31%;
          border: 1px solid rgba(128, 128, 128, 0.18);
          padding: 10px;
          border-radius: 3px;
          box-shadow: $box-shadow-light;
          height: 125px;
          &-list {
            padding-top: 8px;
            width: (100%/3);
            display: flex;
            flex-direction: row;
            min-width: 100%;
            flex-wrap: wrap;
            margin-top: 5px;
            overflow-y: auto;
            max-height: 65px;
            &-item {
              line-height: 25px;
              position: relative;
              text-overflow: ellipsis;
              overflow-x: hidden;
              white-space: nowrap;
              width: 100%;
              color: map-get($ald-colors, 'primary');
              &:before {
                padding-right: 10px;
                @include icon(icon-user);
              }
            }
          }
        }
        &-pos{
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding-top:12px;
          &-culture{
            padding-left:3%;
          }
          &-content{
            width: 31%;
            display: flex;
            flex-direction: column;
            border: 1px solid rgba(128, 128, 128, 0.18);
            padding: 10px;
            border-radius: 3px;
            background-color:map-get($ald-colors, 'light'); ;
            box-shadow: $box-shadow-light;
            height: auto;
            margin: 20px 20px 0 0;
          }
          &-list {
            padding-top: 8px;
            width: (100%/3);
            display: flex;
            flex-direction: row;
            min-width: 100%;
            flex-wrap: wrap;
            margin-top: 5px;
            overflow-y: auto;
            max-height: 65px;
            &-span {
              width: 100%;
              padding: 20px 0 10px 0;
            }
            &-item {
              line-height: 25px;
              position: relative;
              text-overflow: ellipsis;
              overflow-x: hidden;
              white-space: nowrap;
              width: 100%;
            }
          }
        }
        &-contract{
          width: 31%;
          border: 1px solid rgba(128, 128, 128, 0.18);
          padding: 10px;
          border-radius: 3px;
          box-shadow: $box-shadow-light;
          height: 125px;
          margin: 20px 5px 0 0;
          &-list {
            padding-top: 8px;
            width: (100%/3);
            display: flex;
            flex-direction: row;
            min-width: 100%;
            flex-wrap: wrap;
            margin-top: 5px;
            overflow-y: auto;
            max-height: 65px;
            &-span {
              width: 100%;
            }
            &-item {
              line-height: 25px;
              position: relative;
              text-overflow: ellipsis;
              overflow-x: hidden;
              white-space: nowrap;
              width: 100%;
              &:before {
                content: '';
                color: map-get($ald-colors, 'primary');
                padding-right: 10px;
                vertical-align: middle;
                @include icon(icon-contract);
              }
            }
          }
        }

        &-badge {
          width: 31%;
          border: 1px solid rgba(128, 128, 128, 0.18);
          padding: 10px;
          border-radius: 3px;
          box-shadow: $box-shadow-light;
          height: 125px;
          margin: 20px 0 0 5px;
          &-list {
            width: (100%/3);
            display: flex;
            flex-direction: row;
            min-width: 100%;
            flex-wrap: wrap;
            margin-top: 5px;
            overflow-y: auto;
            max-height: 65px;

            &-item {
              width: min-content;
              margin: 7px 7px 0 0;
              line-height: 25px;
              position: relative;
              text-overflow: ellipsis;
              overflow-x: hidden;
              white-space: nowrap;
            }
          }
        }

        &-show {
          cursor: pointer;
          margin: 8px 0;
          color: lighten(map-get($ald-colors, 'primary-light'), 20%);
          width: fit-content;
          font-size: 12px;

          &:hover {
            color: map-get($ald-colors, 'primary');
          }

          &-more {
            &:after {
              margin-left: 4px;
              @include icon(icon-chevron-down);
            }
          }

          &-less {
            &:after {
              margin-left: 4px;
              @include icon(icon-chevron-up);
            }
          }
        }
      }
    }

    .middle-block {
      margin: 20px 5px 0 5px;
    }

    .expand-collapse-row {
      position: relative;
      cursor: pointer;
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 20px;
        line-height: 20px;
        margin: auto;
        right: 20px;
        @include icon(icon-chevron-down);
        transition: all 0.3s ease;
        transform-origin: center center;
        min-height: 0;
      }
    }
  }

  &-loading {
    display: block;
    position: relative;
    padding: 30px;
    @include font-size(14px);
    color: map-get($ald-colors, 'gray-600');
    min-height: 150px;
    ::ng-deep .ring-loader:after{
      width: 25px!important;
      height: 25px!important;
    }
  }
  &-empty{
    text-align: center;
    padding: 40px 0;
    color: map-get($ald-colors, 'gray-500');
    text-transform: uppercase;
    @include font-size(12px);
    font-weight: 600;
  }
  &-note {
    float: right;
    margin: 0 20px 20px 0;
    font-style: italic;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: map-get($ald-colors, 'gray-100');
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map-get($ald-colors, 'gray-300');
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: map-get($ald-colors, 'gray-400');
}
