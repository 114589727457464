// Find the width of 1 column, based upon the column count
// -----------------------------------------------------------------------------
@import '~@ald/ui/lib/styles/abstracts/ald-ui.abstract';

@function find_column_width($columns: 12) {
  $column-width: (100% / $columns);

  @return $column-width;
}

// Create a grid container to house the columns
// -----------------------------------------------------------------------------
@mixin flex-grid($gutter: false, $nested: false) {
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;

  @if $nested {
    flex: 0 1 auto;
  }

  @if $gutter {
    margin-left: -$gutter;
    margin-right: -$gutter;
    > * {
      padding-left: $gutter;
      padding-right: $gutter;
      box-sizing: border-box;
    }
  }
}

// Create a column of the grid
// -----------------------------------------------------------------------------
@mixin flex-col($columns: 0) {
  $width: find_column_width();
  $flex-basis: $width * $columns - 0.1;

  // Setting $columns = 0 will set the column to be auto width and fill available space
  @if ($columns == 0) {
    flex: 1 0 0;
    width: auto;
  } @else {
    flex: 0 0 $flex-basis;
    min-width: $flex-basis;
  }
}


.flex-row {
  @include flex-grid();
}

.flex-col {
  @include flex-col();
}

// All
@for $i from 1 through 12 {
  .col-#{$i} {
      @include flex-col($i);
  }
}

// Large
@for $i from 1 through 12 {
  .col-lg-#{$i} {
    @include screen(l) {
      @include flex-col($i);
    }
  }
}

// Medium
@for $i from 1 through 12 {
  .col-md-#{$i} {
    @include screen(m) {
      @include flex-col($i);
    }
  }
}

// Small
@for $i from 1 through 12 {
  .col-sm-#{$i} {
    @include screen(s) {
      @include flex-col($i);
    }
  }
}
