@import 'abstracts/_imports';

body .mgr-snackbar {
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: $box-shadow;
  @include font-size(12px);
  min-height: 0;
  padding: 15px 30px 15px 15px!important;

  & .icon-x {
    @include center(vertical);
    right: 10px;
  }

  &-info {
    background: map-get($ald-colors, 'info');
    color: map-get($ald-colors, 'light');
  }

  &-success {
    background: map-get($ald-colors, 'success');
    color: map-get($ald-colors, 'light');
  }

  &-warning {
    background-color: map-get($ald-colors, 'warning');
    color: map-get($ald-colors, 'light');
  }

  &-error {
    background: map-get($ald-colors, 'danger');
    color: map-get($ald-colors, 'light');
  }
  a {
    color: map-get($ald-colors, 'primary');
  }
}

.snackbar-content-padded {
  margin-left: 30px;
}

.snackbar-loader {
  @include center(vertical);
  margin-top: 2px;
  left: 25px;
  ::ng-deep {
    .ring-loader:after{
      width: 15px!important;
      height: 15px!important;
      opacity: 0.6;
    }
  }
}
