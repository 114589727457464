@import "abstracts/imports";
@import "base/common";
@import "base/table";
@import "base/forms";
@import "base/badge";
@import "base/snackbar";
@import "base/info-box";
@import "base/multiselect";

@import "base/mat-tab";
@import "base/mat-stepper";
@import "base/mat-datepicker";
@import "base/mat-expansion-panel";
@import "base/timepicker";

input {
  &:focus {
    outline: none;
  }
}

.text {
  &-center {
    text-align: center;
  }
}

.align {
  &-center {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  &-right {
    display: inline-block;
    width: 100%;
    text-align: right;
  }
  &-left {
    text-align: left;
  }
}

.mandatory-field-text {
  color: map-get($ald-colors, 'gray-700');
  font-style: italic;
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 12px;
}

.hide-password-button {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  &:hover {
    cursor: pointer;
  }
}
