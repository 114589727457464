@import "abstracts/_imports";

.ngx-material-timepicker-toggle {
  padding: 0 !important;
  svg {
    position: absolute;
    right: 0;
    top: 8px;
    height: 16px;
  }
}

.timepicker__header {
  background-color: map-get($ald-colors, 'primary-light') !important;
}

.clock-face__number .active {
  background-color: map-get($ald-colors, 'primary-light') !important;
}

.timepicker-button {
  color: map-get($ald-colors, 'primary-light') !important;
}
