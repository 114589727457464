@import 'abstracts/_imports';

// General Styles for Boxes / Card
.box {
  overflow: hidden;
  &-title{
    @include font-size(26px);
    margin-bottom: 30px;
  }
  &-label{
    color: map-get($ald-colors, 'gray-500');
  }
  &-padding {
    padding: 2rem;
  }
  &-link {
    cursor: pointer;
    color: map-get($ald-colors, 'primary');
    border: 1px solid map-get($ald-colors, 'primary');
    padding: 5px 10px;
    margin-bottom: 20px;
    display: block;
    width: max-content;
    text-align: center;
    border-radius: 15px;
    &-icon {
      margin-right: 5px;
    }
    &:hover {
      background-color: transparentize(map-get($ald-colors, 'primary-light'), 0.9);
    }
  }
}

.info {
  background-color: white;
  position: relative;
  padding: 20px;

  &.inline-icon {
    padding-left: 50px;
    .info-icon {
      @include center(vertical);
      left: 0;
    }
  }

  &.block {
    display:inline-block;
    text-align:center;
    .info-icon {
      margin: auto auto 20px auto;
    }
  }

  &-field {
    display: inline-flex;
    padding: 10px 0;
  }

  &-icon {
    display: block;
    width: 35px;
    height: 35px;
    color: map-get($ald-colors, 'primary');
    border-radius: 50%;
    background-color: map-get($ald-colors, 'primary-light');
    position: relative;
    &:before{
      @include center(both);
      @include font-size(12px);
    }
  }

  &-label {
    color: map-get($ald-colors, 'gray-500');
    @include font-size(13px);
    margin-bottom: 5px;
    font-weight: 500;

  }

  &-value {
    overflow-wrap: break-word;
    font-weight: 900;
    color: map-get($ald-colors, 'primary');
    @include font-size(18px);
  }
}


