@import 'abstracts/imports';

.field {
  position: relative;
  padding-top: 1.250em;
  margin: 0 auto 10px auto;
  font-size: em(16px);
  border-bottom: 1px solid map-get($ald-colors, 'primary');

  // when fIcon is set
  &._has-icon {
    .field-input {
      padding-left: 30px;
    }
  }

  // when input has error
  &._has-error {
    border-color: map-get($ald-colors, 'complementary2');

    .field-icon {
      color: map-get($ald-colors, 'complementary2');
    }
  }

  // when input is focused or has value
  &._has-focus, &._has-value {
    .field-label {
      top: 7px;
      font-size: em(12px);
    }

  }

  &-label {
    position: absolute;
    top: 25px;
    left: 0;
    font-size: 1em;
    color: map-get($ald-colors, 'gray-600');
    white-space: nowrap;
    cursor: text;
    transition: top 150ms ease, font-size 200ms ease;
  }

  &-icon {
    position: absolute;
    bottom: 15px;
    color: map-get($ald-colors, 'primary');
  }

  &-input {
    color: map-get($ald-colors, 'primary');
    font-family: $font-family;
    font-weight: 400;
    border: none;
    outline: none;
    padding-top: 8px;
    padding-bottom: 10px;
    font-size: 1em;
    line-height: 1.2em;
    width: 100%;
    background-color: transparent;
    margin: 1px;

    &:disabled, &.disabled, &:read-only {
      color: map-get($ald-colors, 'gray-500')!important;
    }

    &._textarea {
      min-height: 100px;
    }
  }

  &-toggle-password {
    @include center(vertical);
    cursor: pointer;
    display: block;
    width: 25px;
    height: 25px;
    background-color: map-get($ald-colors, 'primary');
    border-radius: $border-radius;
    right: 0;

    &:hover {
      background-color: darken(map-get($ald-colors, 'primary-light'), 5%);
    }

    &:before {
      @include icon('icon-eye');
      @include center(both);
      font-size: 12px;
      color: white;
    }

    &._hide:before {
      @include icon('icon-eye-off');
    }
  }
}

#app .input {
  &-file {
    &-hidden{
      display: none;
    }
    &-field {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      &-infix {
        border-top-width: 6px;
      }
      .mat-form-field-wrapper{
        min-width: 200px;
      }
    }

    &-button {
      margin-left: 10px;
      line-height: normal;
      font-size: 0.8em;
      padding: 8px 15px;

      i {
        margin-right: 5px;
      }
    }
  }
}


.form {
  &-fullwidth{
    width: 100%;
  }
  &-halfwidth{
    width:45%;;
  }
  &-loading{
    position: relative;
    min-height: 40px;
    margin-top: 20px;
  }
  &-field{
    margin: 10px 0 20px 0;
  }
  &-label {
    display: block;
    @include font-size(12px);
    color: map-get($ald-colors, 'gray-600');
    margin-bottom: 15px;
    &.required {
      &:after {
        content: '*';
        padding-left: 3px;
        @include font-size(12px);
        color: map-get($ald-colors, 'danger');
      }
    }
  }
  &-hint {
    margin-top: -20px;
    display: block;
    font-size: 75%;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 10px;
    &.not-mat-form-field {
      margin-top: 20px;
    }
  }
  &-block {
    padding: 20px 15px;
    margin: 10px 0;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: $border-radius;

    mat-checkbox {
      margin-right: 20px;
    }

    mat-label {
      @include font-size(16px);
      margin-bottom: 25px;
      display: block;
    }
  }

  &-toggle-before{
    padding-right: 10px;
    vertical-align: middle;
    line-height: 20px;
    text-transform: uppercase;
    @include font-size(12px);
    color: rgba(0,0,0,0.6);
    font-weight: 600;
  }
  &-error {
    color: map-get($ald-colors, 'complementary2');
    @include font-size(11px);
    margin-top: -12px;
  }
}

.required:after
{
  content: " *";
  color: map-get($ald-colors, 'danger');
  padding-left: 3px;
}

#app mat-form-field {
  margin: 5px 0;
}
