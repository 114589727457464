
#app .mat-form-field-infix .mat-datepicker {
  &-toggle {
    position: absolute;
    width: 102%;
    right: -10px;
    top: -3px;
    text-align: right;

    .mat-icon-button {
      svg {
        width: 1.15em;
      }
    }
  }
}
