body {
  margin: 0;
  background-color: map-get($ald-colors, 'gray-100');
}


input{
  &:focus{
    outline:none;
  }
}

.app{
  &-header{
    padding: 20px 0 30px;
  }
  &-title{
    @include font-size(32px);
    font-weight: 900;
    &-text {
      color: map-get($ald-colors, 'gray-700');
      font-size: 13px;
      margin-top: 20px;
      font-weight: 500;
      &-link {
        color: map-get($ald-colors, 'primary');
        cursor: pointer;
        text-decoration: underline;
      }
      &-users {
        width: 100%;
        max-width: 800px;
        margin-top: 10px;
      }
    }
  }
  &-subtitle {
    @include font-size(18px);
    color: map-get($ald-colors, 'primary');
    font-weight: 500;
    margin-top: 1%;
  }
}

.separator {
  width: 100%;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.border{
  &-right {
    border-right: 1px solid rgba(0,0,0,.1);
  }
  &-left {
    border-right: 1px solid rgba(0,0,0,.1);
  }
}

@include screen(m) {
  .md-border{
    &-right{
        border: 0;
        border-right: 1px solid rgba(0, 0, 0, .1);
    }
    &-left{
      border: 0;
      border-left: 1px solid rgba(0, 0, 0, .1);
    }
    &-bottom{
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, .1);
    }
  }
}

@include screen(s) {
  .sm-border{
    &-right{
      border: 0;
      border-right: 1px solid rgba(0, 0, 0, .1);
    }
    &-left{
      border: 0;
      border-left: 1px solid rgba(0, 0, 0, .1);
    }
    &-bottom{
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, .1);
    }
  }
}
