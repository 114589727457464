@import 'abstracts/_imports';

// General Styles for Badges
.badge{
  @include font-size(11px);
  text-transform: uppercase;
  background-color: map-get($ald-colors, 'gray-300');
  padding: 5px 10px;
  color: white;
  font-weight: 600;
  border-radius: $border-radius;
  letter-spacing: 1px;
  margin-right: 10px;
  min-width: 75px;
  text-align: center;
  &.grey{
    background-color: map-get($ald-colors, 'gray-400');
  }
  &.success{
    background-color: map-get($ald-colors, 'success');
  }
  &.primary{
    background-color: map-get($ald-colors, 'primary');
  }
  &.danger{
    background-color: map-get($ald-colors, 'danger');
  }
  &.info{
    background-color: map-get($ald-colors, 'info');
  }
  &.warning{
    background-color: map-get($ald-colors, 'warning');
  }
}
